export default `
query getHostSlotSettings($input:HostSlotSettingsInput){
  getHostSlotSettings(input:$input){
    time{
      Sunday{
        id
        start
        end
      }
      Monday{
        id
        start
        end
      }
      Tuesday{
        id
        start
        end
      }
      Wednesday{
        id
        start
        end
      }
      Thursday{
        id
        start
        end
      }
      Friday
      {
        id
        start
        end
      }
      Saturday{
        id
        start
        end
      }
    }
  }
}
`;
