import CancelledSvgComponent from "@assets/svg/navbar/cancelled";
import CompletedSvgComponent from "@assets/svg/navbar/completed";
import DashboardSvgComponent from "@assets/svg/navbar/dashboard";
import ManageSvgComponent from "@assets/svg/navbar/manage";
import NewSvgComponent from "@assets/svg/navbar/new";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function DashboardHeader() {
  const [dashboardNavigationArray] = useState([
    {
      linkUrl: "/dashboard",
      imageUrl: "/images/dashboard/navigation/dashboard.svg",
      title: "Dashboard",
    },
    {
      linkUrl: "/new-bookings",
      imageUrl: "/images/dashboard/navigation/offer.svg",
      title: "New Bookings",
    },
    {
      linkUrl: "/completed-bookings",
      imageUrl: "/images/dashboard/navigation/done_all.svg",
      title: "Completed Bookings",
    },
    {
      linkUrl: "/cancelled-bookings",
      imageUrl: "/images/dashboard/navigation/cancelled.svg",
      title: "Cancelled Bookings",
    },
    {
      linkUrl: "/manage-bookings",
      imageUrl: "/images/dashboard/navigation/manage.svg",
      title: "Manage Kiosks",
    },

    // {
    // 	linkUrl: '/followup-overdue',
    // 	imageUrl: '/images/dashboard/navigation/pending.svg',
    // 	title: 'Completed Bookings',
    // },
    // {
    // 	linkUrl: '/interested',
    // 	imageUrl: '/images/dashboard/navigation/thumb-up.svg',
    // 	title: 'Cancelled Bookings',
    // },
    // {
    // 	linkUrl: '/not-interested',
    // 	imageUrl: '/images/dashboard/navigation/thumb-down.svg',
    // 	title: 'Manage Kiosks',
    // },
  ]);

  const match = useLocation();
  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (e.target && e.target.id == "toogle-left-navigation-id_js") {
        document
          .querySelector(".dashboard-navigation")
          .classList.toggle("dashboard-navigation--collapse");
      }
    });
  });

  const getImages = (name, color) => {
    switch (name) {
      case "Dashboard":
        return <DashboardSvgComponent color={color} />;

      case "New Bookings":
        return <NewSvgComponent color={color} />;

      case "Completed Bookings":
        return <CompletedSvgComponent color={color} />;

      case "Cancelled Bookings":
        return <CancelledSvgComponent color={color} />;

      case "Manage Kiosks":
        return <ManageSvgComponent color={color} />;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-navigation">
      <ul>
        {dashboardNavigationArray.map((tempObj, index) => {
          return (
            <li
              className="dashboard-navigation__list"
              key={`dashboard${index}`}
            >
              <NavLink
                to={tempObj.linkUrl}
                className={`dashboard-navigation__link-nav ${
                  match.pathname === tempObj.linkUrl
                    ? "dashboard-navigation__link-nav--active"
                    : ""
                }`}
              >
                {/* <img
                  src={tempObj.imageUrl}
                  className="dashboard-navigation__image"
                  alt=" "
                /> */}
                {getImages(
                  tempObj.title,
                  match.pathname === tempObj.linkUrl ? "#1A73E8" : "#5f6368"
                )}
                <p className="nav_text m-0 ms-3">{tempObj.title}</p>
              </NavLink>
            </li>
          );
        })}
      </ul>
      {/* <div className="dashboard-navigation__icon d-flex">
				<img
					src="/images/generic-images/left-arrow.svg"
					if="toogle-left-navigation-id_js"
					className="dashboard-navigation__image-icon img-fluid cursor-pointer toogle-left-navigation_js"
				/>
			</div> */}
    </div>
  );
}
