import LoadingCard from "@components/loading/loading-card";
import React from "react";

function ListLoader(props) {
  return (
    <div className="datatable_container mt-5">
      <div className="w-95">
        {[1, 2, 3, 4].map((index) => {
          return <LoadingCard key={`fresh-leads-${index}`} />;
        })}
      </div>
    </div>
  );
}

export default ListLoader;
