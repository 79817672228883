import { getValue } from "@utils/lodash";
import React from "react";

function CompletedSvgComponent(props) {
  return (
    <div>
      <svg
        id="done_all_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
      >
        <path
          id="Path_43903"
          data-name="Path 43903"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_43904"
          data-name="Path 43904"
          d="M18,7,16.59,5.59l-6.34,6.34,1.41,1.41Zm4.24-1.41L11.66,16.17,7.48,12,6.07,13.41,11.66,19l12-12ZM.41,13.41,6,19l1.41-1.41L1.83,12Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
          }
        />
      </svg>
    </div>
  );
}

export default CompletedSvgComponent;
