import DashboardLayout from "@components/layout/dashboard-layout.js";
import DashboardCard from "@components/dashboard/dashboard-page/dashboard-card.js";
import "@scss/pages/dashboard/index.scss";
import { useEffect, useState } from "react";
import fetchDashboardDetails from "../services/dashboardDetails/fetchDashboardDetails";
import { getValue } from "../utils/lodash";
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  useEffect(() => {
    getData();
    let name = getValue(localStorage, `user`, "")
      ? JSON.parse(getValue(localStorage, `user`, ""))
      : {};
    setName(getValue(name, `name`, ""));
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await fetchDashboardDetails();
      if (resp) {
        setIsLoading(false);
        console.log(resp);
        setData(getValue(resp, "kioskDashboardDetails", []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <DashboardLayout>
        <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
          <h4 className="dashboard-wrapper__header-title">Dashboard</h4>
          <div></div>
        </div>
        <div className="dashboard_main">
          {isLoading ? (
            <p className="text-center mt-4">Please wait...</p>
          ) : (
            <div>
              <div className="homecontainer">
                <div className="w-40 p-4">
                  <h2>Welcome {name}</h2>
                  <p>
                    This tool serves as a kiosk management system, allowing you
                    to oversee and control individual bookings. You can access
                    diagnostic information, pricing details, and time-slot
                    availability for each reservation. Additionally, you have
                    the capability to review and manipulate the status of kiosks
                    assigned to you, adjusting slot availability as needed.
                     {/* The
                    management of time slots for each kiosk is conveniently
                    facilitated through this platform. */}
                  </p>
                </div>
                <div className=" p-4">
                  <img src={`/images/dashboard/sales-analytics.png`} />
                </div>
              </div>
              <div className="dashboard-home-wrapper p-4">
                {isLoading ? (
                  "Please wait..."
                ) : getValue(data, "length", 0) > 0 ? (
                  <DashboardCard data={data} />
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </div>
            </div>
          )}
        </div>
      </DashboardLayout>
    </>
  );
}
