export function removeNullValuesFromObjectArray(arr) {
    return arr.map((item) => {
      const filteredObj = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const value = item[key];
          if (
            (Array.isArray(value) && value.length !== 0 && value[0] !== "") || // Check for non-empty array with non-empty string
            (!Array.isArray(value) &&
              value !== null &&
              value !== undefined &&
              value !== "") // Check for non-empty string or non-array value
          ) {
            filteredObj[key] = value;
          }
        }
      }
      return filteredObj;
    });
  }