import ManageSvgComponent from "@assets/svg/navbar/manage";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function BookingTable(props) {
  const navigate = useNavigate();
  return (
    <div>
      <table className="datatable_table w-100 mt-4">
        <thead className="datatable_table_thead">
          <th className="datatable_table_th" style={{ width: "50px" }}></th>
          <th className="datatable_table_th">
            <h6>Kiosk ID</h6>
            <p></p>
          </th>
          <th className="datatable_table_th">
            <h6>City</h6>
            <p>Location</p>
          </th>
          <th className="datatable_table_th">
            <h6>Kiosk Name</h6>
            <p></p>
          </th>
          <th className="datatable_table_th">
            <h6>Status</h6>
          </th>
        </thead>
        <tbody>
          {getValue(props, `list`, []).map((item) => {
            return (
              <tr
                className="datatable_table_tr"
                onClick={() =>
                  navigate(`/manage-bookings/${getValue(item, `id`, "")}/${getValue(item, `name`, "")}`)
                }
              >
                <td className="datatable_table_td">
                  <ManageSvgComponent />
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `id`, "")}</h6>
                  <p></p>
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `location.city.name`, "")}</h6>
                  <p></p>
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `name`, "")}</h6>
                  <p></p>
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `status`, "")}</h6>
                  <p></p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default BookingTable;
