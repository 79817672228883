import { getValue } from "@utils/lodash";
import React from "react";

function ManageSvgComponent(props) {
  return (
    <div>
      <svg
        id="room_preferences_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
      >
        <rect
          id="Rectangle_14238"
          data-name="Rectangle 14238"
          width="24"
          height="24"
          fill="none"
        />
        <path
          id="Path_43907"
          data-name="Path 43907"
          d="M21.69,16.37l1.14-1-1-1.73-1.45.49a3.647,3.647,0,0,0-1.08-.63L19,12H17l-.3,1.49a3.647,3.647,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1a3.338,3.338,0,0,0,0,1.26l-1.14,1,1,1.73,1.45-.49a3.647,3.647,0,0,0,1.08.63L17,22h2l.3-1.49a3.647,3.647,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1A3.39,3.39,0,0,0,21.69,16.37ZM18,19a2,2,0,1,1,2-2A2.006,2.006,0,0,1,18,19ZM19,4v6H17V6H15v6H13V5H7V19h5v2H3V19H5V3H15V4Zm-7,9H10V11h2Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
          }
        />
      </svg>
    </div>
  );
}

export default ManageSvgComponent;
