import { getValue } from "@utils/lodash";
import React from "react";

function CancelledSvgComponent(props) {
  return (
    <div>
      <svg
        id="event_busy_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
      >
        <path
          id="Path_43905"
          data-name="Path 43905"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_43906"
          data-name="Path 43906"
          d="M19,3H18V1H16V3H8V1H6V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V9H19ZM5,7V5H19V7Zm3.23,9.41,1.06,1.06,2.44-2.44,2.44,2.44,1.06-1.06-2.44-2.44,2.44-2.44-1.06-1.06-2.44,2.44L9.29,10.47,8.23,11.53l2.44,2.44Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
          }
        />
      </svg>
    </div>
  );
}

export default CancelledSvgComponent;
