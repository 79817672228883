export default `
query kioskOrders($sort:String,$limit:Int,$start:Int,$where:JSON){
  kioskOrders(sort:$sort,limit:$limit,start:$start,where:$where){
    id
    createdAt
    name
    phone_no
    email
    date
    time
    amount
    comment
    published_at
    status
    kiosk_tests{
      id
      createdAt
      name
      description
      original_price
      discount_price
    }
    kiosk_payment{
      id
      payment_type
      createdAt
      amount
      currency
      payment_id
      name
      phone_no
      email
      date
      time
    }
  }
}
`;
