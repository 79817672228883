export default `
query kioskOrder($id:ID!){
  kioskOrder(id:$id){
    id
    createdAt
    name
    phone_no
    email
    date
    time
    amount
    comment
    published_at
    status
    kiosk{
      location{
        name
        city{
          name
          state{
            name
            country{
              name
            }
          }
        }
      }
    }
    kiosk_tests{
      id
      createdAt
      name
      description
      original_price
      discount_price
    }
    kiosk_payment{
      id
      payment_type
      createdAt
      amount
      currency
      payment_id
      name
      phone_no
      email
      date
      time
    }
  }
}
`;
