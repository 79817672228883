import graphQLRequest from "../graphqlRequest";
import createProviderLeadComment from "./handleTimeSlotSettings";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchHandleTimeSlotSettings(input) {
    const datas = await graphQLRequest(createProviderLeadComment,input);
    return datas;
}