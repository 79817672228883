import LoginLayout from "@components/layout/login-layout.js";
import Login from "@components/home-page/login-form.js";
import "@scss/pages/home-page/index.scss";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import fetchLoginProviderUser from "../services/loginProviderUser/fetchLoginProviderUser";
import { getValue } from "../utils/lodash";

export default function HomePage(props) {
  const [request, setRequest] = useState({
    email: "",
    pwd: "",
  });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let resp = await fetchLoginProviderUser({ input: request });
        if (resp) {
          console.log(resp);
          setIsLoading(false);
          localStorage.setItem(
            "jwt",
            getValue(resp, "loginKioskUser.jwt", "")
          );
          localStorage.setItem(
            "user",
            JSON.stringify(getValue(resp, "loginKioskUser.user", ""))
          );
          window.location.href = "/dashboard"
          // props.history.push("/dashboard");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };


  return (
    <LoginLayout>
      <Login
        request={request}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        simpleValidator={simpleValidator}
      />
    </LoginLayout>
  );
}
