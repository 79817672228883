import graphQLRequest from "../graphqlRequest";
import providerLeads from "./kioskOrders";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchKioskOrder(id) {
    const datas = await graphQLRequest(providerLeads,id);
    return datas;
}