import CancelledSvgComponent from "@assets/svg/navbar/cancelled";
import CompletedSvgComponent from "@assets/svg/navbar/completed";
import DashboardSvgComponent from "@assets/svg/navbar/dashboard";
import ManageSvgComponent from "@assets/svg/navbar/manage";
import NewSvgComponent from "@assets/svg/navbar/new";
import { getValue } from "@utils/lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

function ListTable(props) {
  const navigate = useNavigate();
  const readableFormat = (timeString) => {
    let time = timeString
      ? moment(timeString, "HH:mm:ss.SSS").format("h:mm A")
      : "";
    return time;
  };

  const convertDate = (date) => {
    return date ? moment(new Date(date)).format("DD MMM YYYY") : null;
  };

  const getImages = () => {
    switch (props.type) {
      case "new":
        return <NewSvgComponent  />;

      case "completed":
        return <CompletedSvgComponent  />;

      case "cancelled":
        return <CancelledSvgComponent  />;

      case "manage":
        return <ManageSvgComponent  />;
      default:
        return null;
    }
  };

  return (
    <div>
      <table className="datatable_table w-100 mt-4">
        <thead className="datatable_table_thead">
          <th className="datatable_table_th" style={{width:'50px'}}></th>
          <th className="datatable_table_th">
            <h6>Booking Date</h6>
            <p>Booking Time</p>
          </th>
          <th className="datatable_table_th">
            <h6>Customer Name</h6>
            <p>Phone Number</p>
          </th>
          <th className="datatable_table_th">
            <h6>Payment Type</h6>
            <p></p>
          </th>
          <th className="datatable_table_th">
            <h6>Status</h6>
          </th>
        </thead>
        <tbody>
          {getValue(props, `list`, []).map((item) => {
            return (
              <tr
                className="datatable_table_tr"
                onClick={() =>
                  navigate(
                    `/${getValue(
                      item,
                      `status`,
                      ""
                    ).toLowerCase()}-bookings/${getValue(item, `id`, "")}`
                  )
                }
              >
                <td className="">{getImages()}</td>
                <td className="datatable_table_td">
                  <h6>{convertDate(getValue(item, `date`, ""))}</h6>
                  <p>{readableFormat(getValue(item, `time`, ""))}</p>
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `name`, "")}</h6>
                  <p>{getValue(item, `phone_no`, "")}</p>
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `kiosk_payment.payment_type`, "")}</h6>
                  <p></p>
                </td>
                <td className="datatable_table_td">
                  <h6>{getValue(item, `status`, "")}</h6>
                  <p></p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ListTable;
