import React from "react";
import ReactDOM from "react-dom/client";
import App from "./routes/index";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, Slide } from "react-toastify";
import "@scss/common/base.scss";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

// Apollo client setup
const client = new ApolloClient({
  uri: "https://backend.healthtunnel.com/graphql",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <ToastContainer
        theme="dark"
        position="bottom-center"
        autoClose={15000}
        transition={Slide}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={2}
        pauseOnFocusLoss
        pauseOnHover
      />
      <App />
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
