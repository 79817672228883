export default `
query kiosks($sort:String,$limit:Int,$start:Int,$where:JSON){
  kiosks(sort:$sort,limit:$limit,start:$start,where:$where){
    id
    name
    status
    location{
      id
      city{
        id
        name
      }
    }
  }
}
`;
