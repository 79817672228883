import Aboutus from "@pages/about-us.js";
import Dashboard from "@pages/dashboard.js";
import FreshLeadsInnerPage from "@pages/inside/fresh-leads-inner-page.js";
import FollowUpInnerpage from "../pages/inside/followup-inner-page";
import IntrestedInnerpage from "../pages/inside/interested-inner-page";
import NotIntrestedInnerpage from "../pages/inside/not-interested-inner-page";
import NewBookingsPage from "@pages/new-bookings";
import CompletedBookingPage from "@pages/completed-bookings";
import CancelledBookingPage from "@pages/cancelled-bookings";
import ManageBookingsDetail from "@pages/manage-bookings/detail";
import ManageBookingPage from "@pages/manage-bookings";
import BookingInnerPage from "@pages/inside/booking-inner-page";

const PrivateRoutes = [
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/new-bookings", name: "NewBookings", component: NewBookingsPage },
  {
    path: "/completed-bookings",
    name: "NewBookings",
    component: CompletedBookingPage,
  },
  {
    path: "/cancelled-bookings",
    name: "NewBookings",
    component: CancelledBookingPage,
  },
  {
    path: "/:name/:id",
    name: "NewBookings",
    component: BookingInnerPage,
  },

  {
    path: "/manage-bookings",
    name: "NewBookings",
    component: ManageBookingPage,
  },
  {
    path: "/manage-bookings/:id/:name",
    name: "NewBookings",
    component: ManageBookingsDetail,
  },

  {
    path: "/fresh-leads-page/:id",
    name: "FreshLeadsInnerPage",
    component: FreshLeadsInnerPage,
  },
  {
    path: "/followup-page/:id",
    name: "FollowUpInnerpage",
    component: FollowUpInnerpage,
  },
  {
    path: "/interested-page/:id",
    name: "IntrestedInnerpage",
    component: IntrestedInnerpage,
  },
  {
    path: "/not-interested-page/:id",
    name: "NotIntrestedInnerpage",
    component: NotIntrestedInnerpage,
  },
  { path: "/about-us", name: "Aboutus", component: Aboutus },
];

export default PrivateRoutes;
