import { getValue } from "@utils/lodash";
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

function SlotPopup(props) {
  const {
    isOpen,
    isLoading,
    toggle,
    handleSubmit,
    hostSlots,
    setHostSlots,
    hostSlotSettings,
  } = props;
  const convertToGMT = (time) => {
    if (time && time != 0) {
      const formattedTime = moment(time, "HH:mm:ss").format("HH:mm:ss");
      const dateTimeString =
        moment().format("YYYY-MM-DD") + "T" + formattedTime;
      return new Date(dateTimeString);
    } else {
      return null;
    }
  };
  console.log(hostSlots);
  return (
    <Modal isOpen={isOpen} centered size="lg">
      <ModalHeader>Slot Settings</ModalHeader>
      <ModalBody>
        <div className="settings_container">
          {hostSlots.map((item, i) => {
            return (
              <div className="d-flex justify-content-between align-items-center px-3 w-100">
                <p className="w-50 text">{getValue(item, `day`, "")}</p>
                <div className="d-flex w-50 mb-2">
                  <div>
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      onChange={(e) =>
                        setHostSlots((options) => {
                          return options.map((x, index) => {
                            if (index !== i) return x;
                            return {
                              ...x,
                              start: moment(e).format("HH:mm:ss"),
                            };
                          });
                        })
                      }
                      selected={convertToGMT(getValue(item, `start`, null))}
                      timeFormat="hh:mm a"
                      dateFormat="hh:mm a"
                      placeholderText="Start Time"
                      className="time-picker-slot"
                    />
                  </div>
                  <div className="ms-2">
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      onChange={(e) =>
                        setHostSlots((options) => {
                          return options.map((x, index) => {
                            if (index !== i) return x;
                            return {
                              ...x,
                              end: moment(e).format("HH:mm:ss"),
                            };
                          });
                        })
                      }
                      selected={convertToGMT(getValue(item, `end`, null))}
                      timeFormat="hh:mm a"
                      dateFormat="hh:mm a"
                      placeholderText="End Time"
                      className="time-picker-slot"
                    />
                  </div>
                  {/* {getValue(hostSlotSettings, `[${item}].start`, "")} -{" "}
                  {getValue(hostSlotSettings, `[${item}].end`, "")} */}
                </div>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center p-1 gap-2">
            <button
              className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-secondary-btn"
              style={{ fontWeight: "400" }}
              onClick={toggle}
            >
              Cancel
            </button>
          <button
            className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn"
            onClick={handleSubmit}
            disabled={isLoading}
            style={{ fontWeight: "400" }}
          >
            {isLoading ? "Please wait..." : "Save"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default SlotPopup;
