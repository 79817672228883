import { getValue } from "@utils/lodash";
import React from "react";
import { NavLink } from "react-router-dom";

function BookingFixedHeader(props) {
  const { isLoading, handleSubmit } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <NavLink to={props.route}>
                <img
                  src="/images/generic-images/back-arrow.svg"
                  className="img-fluid"
                />
              </NavLink>
              <p className="header_text">{getValue(props, `title`, "")}</p>
            </div>
          </div>
          <div className="d-flex align-items-center p-1 gap-2">
            <NavLink to={props.route}>
              <button
                className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-secondary-btn"
                style={{ fontWeight: "400" }}
              >
                Cancel
              </button>
            </NavLink>
            <button
              className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn"
              onClick={handleSubmit}
              disabled={isLoading}
              style={{ fontWeight: "400" }}
            >
              {isLoading ? "Please wait..." : "Save & Submit"}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BookingFixedHeader;
