import React, { useEffect, useState } from "react";
import Card from "@components/dashboard/common-dashboard-card.js";
import Header from "@components/dashboard/fresh-leads/header.js";
import DashboardLayout from "@components/layout/dashboard-layout.js";
import fetchProviderLeads from "../../services/providerLeads/fetchProviderLeads";
import { getValue } from "../../utils/lodash";
import Pagination from "react-js-pagination";
import fetchProviderLeadsCount from "../../services/providerLeadsCount/fetchProviderLeadsCount";
import NotFound from "@components/not-found/index.js";
import LoadingCard from "@components/loading/loading-card";
import moment from "moment";
import qs from "query-string";
import fetchKiosks from "@services/kiosks/fetchKioskOrders";
import BookingTable from "./table";
import ListLoader from "@pages/components/loader";

export default function ManageBookingPage(props) {
  // ************************************************************** //
  // *******************    Use Effect Section   ****************** //
  // ************************************************************** //

  useEffect(() => {
    getData(limit, start);

    const urlSearchParams = new URLSearchParams(
      process.browser && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());

    let page = window.location.search.split("?page=")[1];
    let activePage = page ? page.split("0") : 1;
    setStart(parseFloat(page ? page : 1));
    setActivePage(
      activePage
        ? parseFloat(page) > 9
          ? parseFloat(activePage) + 1
          : parseFloat(activePage)
        : 1
    );
    if (params.createdAt_gte && params.createdAt_lte) {
      setDateRange([
        new Date(params.createdAt_gte),
        new Date(params.createdAt_lte),
      ]);
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(
      process.browser && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    let page = window.location.search.split("?page=")[1];
    let activePage = page ? page.split("0") : 1;
    setStart(parseFloat(page ? page : 1));
    setActivePage(
      activePage
        ? parseFloat(page) > 9
          ? parseFloat(activePage) + 1
          : parseFloat(activePage)
        : 1
    );
    if (params.createdAt_gte && params.createdAt_lte) {
      setDateRange([
        new Date(params.createdAt_gte),
        new Date(params.createdAt_lte),
      ]);
    }
    getData(limit, start);
  }, [process.browser && window.location.href]);

  // ************************************************************** //
  // *******************    Use State Section   ****************** //
  // ************************************************************** //

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [freshLeads, setFreshLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [start, setStart] = useState(
    window.location.search.split("?page=")[1]
      ? window.location.search.split("?page=")[1]
      : 1
  );
  const [limit] = useState(10);

  const handleClearDate = () => {
    setDateRange([null, null]);
    const urlSearchParams = new URLSearchParams(
      process.browser && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      page: params.page,
    };
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    props.history.push(`/fresh-leads?${queryRequest}`);
  };

  const handleApplyDateFilter = () => {
    let createdAt_gte = moment(startDate).format("YYYY-MM-DD");
    let createdAt_lte = moment(endDate).format("YYYY-MM-DD");

    let payload = {
      page: "1",
      createdAt_gte: createdAt_gte,
      createdAt_lte: createdAt_lte,
    };
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    props.history.push(`/fresh-leads?${queryRequest}`);
  };

  // ************************************************************** //
  // **********************   API Section     ********************** //
  // ************************************************************** //

  const getData = async (limit, start) => {
    let starts = parseFloat(start) - 1;
    const urlSearchParams = new URLSearchParams(
      process.browser && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      setIsLoading(true);
      let where = {
        // status: "Cancelled",
        // createdAt_gte: params.createdAt_gte
        // 	? params.createdAt_gte
        // 	: startDate
        // 	? moment(startDate).format("YYYY-MM-DD")
        // 	: "",
        // createdAt_lte: params.createdAt_lte
        // 	? params.createdAt_lte
        // 	: endDate
        // 	? moment(endDate).format("YYYY-MM-DD")
        // 	: "",
      };

      Object.keys(where).forEach((key) => {
        if (!where[key] || where[key] === undefined) {
          delete where[key];
        }
      });

      let resp = await fetchKiosks(limit, parseFloat(starts), where);
      // let count = await fetchProviderLeadsCount(where);
      if (resp) {
        console.log(resp);
        setIsLoading(false);
        setFreshLeads(getValue(resp, "kiosks", []));
        // setTotalCount(getValue(count, `providerLeadsCount`, 0));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  console.log(freshLeads, "---");
  // ************************************************************** //
  // *****************   Pagination Section      ****************** //
  // ************************************************************** //

  const handlePageChange = (start) => {
    setActivePage(start);
    if (parseFloat(start) === 1) {
      setStart(start);
      props.history.push({
        pathname: "/fresh-leads",
        search: "?page=" + start,
      });
      getData(limit, start);
    } else {
      let currentPage =
        (parseFloat(start) - 1) * 10 + (parseFloat(start) - parseFloat(start));
      setStart(currentPage);
      // getData(limit,currentPage);
      props.history.push({
        pathname: "/fresh-leads",
        search: "?page=" + currentPage,
      });
      getData(limit, currentPage);
    }
  };

  return (
    <DashboardLayout>
      <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
        <h4 className="dashboard-wrapper__header-title">Manage Kiosks</h4>
        <div>
          {/* <Header
            dateRange={dateRange}
            setDateRange={setDateRange}
            startDate={startDate}
            endDate={endDate}
            handleClearDate={handleClearDate}
            handleApplyDateFilter={handleApplyDateFilter}
          /> */}
        </div>
      </div>

      {isLoading ? (
        <ListLoader />
      ) : freshLeads.length > 0 ? (
        <div className="datatable_container">
          <div className="w-95">
            <BookingTable list={freshLeads} type={"manage"} />
          </div>
        </div>
      ) : (
        <NotFound />
      )}
      {totalCount > 10 ? (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      ) : (
        ""
      )}
    </DashboardLayout>
  );
}
