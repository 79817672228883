export default `
mutation handleTimeSlotSettings($input:HandleTimeSlotSettingInput) { 
  handleTimeSlotSettings(input:$input){ 
    time{
      Sunday{
        start
        end
      }
      Monday{
        start
        end
      }
    }
    }
    }
`;
