import graphQLRequest from "../graphqlRequest";
import createProviderLeadComment from "./mangeExcludeBookSlots";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchMangeExcludeBookSlots(input) {
  const datas = await graphQLRequest(createProviderLeadComment, {
    input: input,
  });
  return datas;
}
