import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { formatText } from "../../../common/formatString";
import { getValue } from "../../../utils/lodash";

export default function DashboardCard(props) {
  const [dashboardCardArray] = useState([
    {
      title: "Fresh Leads",
      count: "018",
      imgIcon: "/images/dashboard/dashboard-home-page/Group 8496.svg",
      href: "/fresh-leads",
    },
    {
      title: "Followup Overdue",
      count: "011",
      imgIcon: "/images/dashboard/dashboard-home-page/Group 8564.svg",
      href: "/followup-overdue",
    },
    {
      title: "Interested",
      count: "018",
      imgIcon: "/images/dashboard/dashboard-home-page/Group 8566.svg",
      href: "/interested",
    },
    {
      title: "Not Interested",
      count: "018",
      imgIcon: "/images/dashboard/dashboard-home-page/Group 8565.svg",
      href: "/not-interested",
    },
  ]);
  const navigate = useNavigate();

  const url = (url) => {
    switch (url) {
      case "new":
        return "/new-bookings?page=1";
      case "completed":
        return "/completed-bookings?page=1";
      case "cancelled":
        return "/cancelled-bookings?page=1";
      default:
        break;
    }
  };

  const images = (path) => {
    console.log(path);
    switch (path) {
      case "new":
        return "/images/dashboard/dashboard-home-page/new.svg";
      case "completed":
        return "/images/dashboard/dashboard-home-page/completed.svg";
      case "cancelled":
        return "/images/dashboard/dashboard-home-page/cancelled.svg";
      case "total":
        return "/images/dashboard/dashboard-home-page/total.svg";
      default:
        break;
    }
  };
  return (
    <div className="d-flex flex-wrap justify-content-center">
      {getValue(props, "data", []).map((tempObj, index) => {
        return (
          <div
            className="cursor-pointer dashboard-card-wrapper d-flex align-items-center justify-content-between p-4"
            onClick={() => navigate(url(tempObj.status.toLowerCase()))}
          >
            <div>
              <h2 className="dashboard-card-wrapper__count text-black">
                {tempObj.count}
              </h2>
              <p className="dashboard-card-wrapper__title">
                {formatText(tempObj.status)} Bookings
              </p>
            </div>
            <img
              src={images(tempObj.status.toLowerCase())}
              className="img-fluid"
            />
          </div>
        );
      })}
    </div>
  );
}
