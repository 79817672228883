import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";

export const formatText = (str) => {
  if (str) {
    const arr = str && str.split("_");
    const result = [];
    for (const word of arr) {
      result.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // 👈️ lowercase remainder
      );
    }
    return result.join(" ");
  }
};

export const formatSmallString = (str) => {
  let length = str ? str.length : 0;
  return length > 30 ? str.substring(0, 30).concat("...") : str;
};

export const convertStringToNumber = (str) => {
  return str ? parseInt(str) : str;
};

export const getFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() : str;
};

export const getFirstLetterOfEachString = (str) => {
  let acronym = str
    ? str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
    : "";
  return acronym.toUpperCase();
};
export const CopyToClipboard = (value) => {
  /* Get the text field */
  //   var copyText = event.currentTarget.firstElementChild.nextElementSibling.value;
  /* Copy the text inside the text field */
  navigator.clipboard.writeText(value);
  /* Alert the copied text */
  // alert("Copied: " + value);
  toast.success(`${value}${" "} copied to clipboard`);
};

export const formatDecimal = (value) => {
  if (value) {
    return value ? parseFloat(value).toFixed(2) : value;
  } else {
    return value;
  }
};

export const textCapitalize = (str) => {
  const arr = str.split("_");
  const result = [];
  for (const word of arr) {
    result.push(
      word.toUpperCase() // 👈️ lowercase remainder
    );
  }
  return result.join(" ");
};

export const concatDotToString = (value) => {
  if (value) {
    return getValue(value, `length`, 0) > 0
      ? value.substring(0, 100).concat("...")
      : value;
  } else {
    return value;
  }
};

export const concatDotToDocumentString = (value) => {
  if (value) {
    return getValue(value, `length`, 0) > 20
      ? value.substring(0, 20).concat("...")
      : value;
  } else {
    return value;
  }
};

export function json2array(json) {
  var result = [];
  var keys = Object.keys(json);
  keys.forEach(function (key) {
    result.push({
      [key]: json[key],
    });
  });
  return result;
}

export const concatString = (str, length) => {
  return str
    ? str.length > length
      ? str.substring(0, length).concat("...")
      : str
    : null;
};

export const numberFormatter = (num) => {
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const capitalizeFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const removeNullOrUndefinedProperties = (obj) => {
  // return removeNullValues(obj);
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        (obj[key] === null ||
          obj[key] === "" ||
          obj[key] === undefined ||
          (Array.isArray(obj[key]) && obj[key].length === 0)) &&
        typeof obj[key] !== "boolean"
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
  // for (let key in obj) {
  //   if (obj.hasOwnProperty(key)) {
  //     if (
  //       obj[key] === null ||
  //       obj[key] === "" ||
  //       obj[key] === undefined ||
  //       (Array.isArray(obj[key]) && obj[key].length === 0)
  //     ) {
  //       delete obj[key];
  //     }
  //   }
  // }
  // return obj;
};
export const removeNullOrUndefinedPropertiesKeepArray = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        (obj[key] === null || obj[key] === "" || obj[key] === undefined) &&
        typeof obj[key] !== "boolean"
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
};
export function removeNullValuesFromObjectArray(arr) {
  return arr.map((item) => {
    const filteredObj = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const value = item[key];
        if (value !== null && value !== undefined) {
          if (typeof value !== "boolean") {
            filteredObj[key] = !isNaN(value) ? Number(value) : value;
          } else {
            filteredObj[key] = value;
          }
        }
      }
    }
    return filteredObj;
  });
}
export function removeEmptyProperties(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeEmptyProperties(item));
  } else if (typeof obj === "object" && obj !== null) {
    const cleanedObj = {};

    for (const key in obj) {
      const value = removeEmptyProperties(obj[key]);
      if (
        value !== null &&
        value !== undefined &&
        value !== 0 &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0) && // Check for non-empty arrays
        (typeof value !== "object" || Object.keys(value).length > 0) // Check for non-empty objects
      ) {
        cleanedObj[key] = value;
      }
    }
    return cleanedObj;
  } else {
    return obj;
  }
}

export function removeEmptyPropertiesWithZero(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeEmptyProperties(item));
  } else if (typeof obj === "object" && obj !== null) {
    const cleanedObj = {};

    for (const key in obj) {
      const value = removeEmptyProperties(obj[key]);
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0) && // Check for non-empty arrays
        (typeof value !== "object" || Object.keys(value).length > 0) // Check for non-empty objects
      ) {
        cleanedObj[key] = value;
      }
    }
    return cleanedObj;
  } else {
    return obj;
  }
}

export const formatAmount = (amount) => {
  if (typeof amount === "number") {
    return amount.toFixed(2);
  } else if (typeof amount === "string") {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount)) {
      return parsedAmount.toFixed(2);
    } else {
      return amount;
    }
  } else {
    return amount;
  }
};

export const findValueFromOptions = (options, name) => {
  let filters =
    getValue(options, `length`, 0) > 0
      ? options.filter((item) => getValue(item, `value`, "") === name)
      : [];
  if (getValue(filters, `length`, 0) > 0) {
    return getValue(filters, `[${0}].label`, "");
  }
};
export const filteredData = (data) => {
  const filteredItems = []; // Initialize an array to hold the filtered items

  data.map((item) => {
    const filteredItem = {};
    for (const [key, value] of Object.entries(item)) {
      if (value !== null) {
        if (typeof value === "object" && !Array.isArray(value)) {
          const nonEmptySubObject = {};
          for (const [subKey, subValue] of Object.entries(value)) {
            if (subValue !== null && subValue !== "") {
              nonEmptySubObject[subKey] = subValue;
            }
          }
          if (Object.keys(nonEmptySubObject).length > 0) {
            filteredItem[key] = nonEmptySubObject;
          }
        } else if (value !== "") {
          filteredItem[key] = value;
        }
      }
    }
    filteredItems.push(filteredItem); // Add the filtered item to the array
  });

  return filteredItems; // Return the array of filtered items
};

export const convertElementaryArrayToJSONArray = (arr) => {
  if (getValue(arr, `length`, 0) > 0) {
    return arr.map((item) => ({
      value: item,
      label: formatText(item),
    }));
  } else {
    return [];
  }
};

export const sortJSONObjectArray = (arr, key) => {
  if (getValue(arr, `length`, 0) > 0) {
    return arr.sort((a, b) => a[key] - b[key]);
  } else {
    return [];
  }
};

export function camelToSnake(str) {
  if (str)
    str =
      str[0].toLowerCase() +
      str
        .slice(1, str.length)
        .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  str = str.replaceAll(" _", "_");

  return str.replaceAll(" ", "_").replace(/(^_*|_*$)/g, "");
}
