import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Link from "react";
import { getValue } from "../../../utils/lodash";
export default function DashboardHeader(props) {
  useEffect(() => {
    document
      .querySelector(".toogle-left-navigation-manburger_js")
      .addEventListener("click", function () {
        document
          .querySelector(".dashboard-navigation")
          .classList.toggle("dashboard-navigation--collapse");
      });
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    let user = localStorage.getItem("user");
    let userInfo = JSON.parse(user);
    setUserInfo(userInfo ? userInfo : {});
    console.log(userInfo);
  }, []);
  return (
    <header className="dashboard-header position-relative">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="dashboard-header__logo">
          <img
            src="/images/dashboard/navigation/menu.svg"
            className="dashboard-header__menu-icon img-fluid cursor-pointer toogle-left-navigation-manburger_js"
            alt=" "
          />
          <a href="/dashboard">
            <img
              src="/images/home-page/logo.svg"
              className="img-fluid"
              alt=" "
            />
          </a>
        </div>
        {/* <div className="dashboard-header__logedin-user">
          <h2 className="dashboard-header__initial">S</h2>
        </div> */}
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle>
            <div className="dashboard-header__logedin-user">
              <h2 className="dashboard-header__initial">
                {getValue(userInfo, "name", "")
                  ? getValue(userInfo, "name", "").charAt(0)
                  : "H"}
              </h2>
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            <div className="dashboard-header__account">
              <h3 className="normal-font mb-2" style={{ fontSize: "18px" }}>
                Account
              </h3>
              <hr />
              <div className="dashboard-header__logedin-user dashboard-header__logedin-user--dimention">
                <h2 className="dashboard-header__initial dashboard-header__initial--font">
                  {getValue(userInfo, "name", "")
                    ? getValue(userInfo, "name", "").charAt(0)
                    : "H"}
                </h2>
              </div>
              <h3
                className="text-left normal-font mb-0 cursor-pointer text-capitalize"
                style={{ fontSize: "18px" }}
              >
                {getValue(userInfo, "name", "")}
              </h3>
              <p
                className="text-left light-font mb-2 cursor-pointer"
                style={{ fontSize: "14px" }}
              >
                {getValue(userInfo, "email", "")}
              </p>
            </div>

            <div
              className="d-flex justify-content-center mt-3 mb-3 px-3"
              onClick={handleLogout}
            >
              <button className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn">
                Signout
              </button>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </header>
  );
}
