import "@scss/components/home-page/login-form.scss";
import InputField from "../../common/InputRuleField";
import { getValue } from "../../utils/lodash";

export default function Login(props) {
  const handleKeyDown = (e) => {
    console.log(e.key, e.target.tagName.toLowerCase());
    if (e.key === "Enter" && e.target.tagName.toLowerCase() !== "button") {
      e.preventDefault();
      props.handleSubmit(e);
    }
  };

  return (
    <div className="login-wrapper">
      <img
        src="/images/home-page/logo.svg"
        alt=" "
        className="login-wrapper__logo-img"
      />
      <h2 className="login-wrapper__title">Login</h2>
      {/* <form autoComplete="on"> */}
      <div className="form-group">
        <label htmlFor="email-address">Email Address</label>
        <InputField
          inputType="EMAIL"
          id="email-address"
          name="email"
          placeholder="Enter Email ID"
          value={getValue(props, "request.email")}
          onChange={props.handleChange}
          className="login-wrapper__form-element form-control"
          validator={props.simpleValidator}
          label="Email Address"
          autoComplete="on"
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <InputField
          inputType="PASSWORD"
          id="pwd"
          name="pwd"
          value={getValue(props, "request.pwd")}
          onChange={props.handleChange}
          placeholder="Enter Password"
          className="form-control login-wrapper__form-element"
          validator={props.simpleValidator}
          label="Password"
          autoComplete="on"
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="login-wrapper__cta-wrapper d-flex align-items-center justify-content-between">
        <a href="password-reset">
          <button className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-secondary-btn">
            Forgot password?
          </button>
        </a>

        <button
          className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn"
          onClick={props.handleSubmit}
          disabled={props.isLoading}
        >
          {props.isLoading ? "Please wait..." : "Login"}
        </button>
      </div>
      {/* </form> */}
    </div>
  );
}
