import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import PublicRoutes from "./public.routes";
import PrivateRoutes from "./private.routes";

export default function HealthtunnelRouting() {
  const isAuthenticated = !!localStorage.getItem("jwt");

  return (
    <BrowserRouter>
      <Routes>
        {PublicRoutes.map((routeObj, key) => {
          const Element = routeObj.component;
          return (
            <Route
              key={key}
              path={routeObj.path}
              element={isAuthenticated ? <Navigate to="/dashboard" /> : <Element />}
            />
          );
        })}
        {PrivateRoutes.map((routeObj, key) => {
          const Element = routeObj.component;
          return (
            <Route
              key={key}
              path={routeObj.path}
              element={isAuthenticated ? <Element /> : <Navigate to="/signin" />}
            />
          );
        })}
        {/* Add a catch-all route for unknown paths */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
