import Aboutus from "@pages/about-us.js";
import PasswordReset from "@pages/password-reset.js";
import EmailSent from "@pages/email-sent.js";
import PasswordLogin from "@pages/login-pwd.js";
import HomePage from "@pages/home-page";

const PublicRoutes = [
  { path: "/", component: HomePage },
  { path: "/signin", component: HomePage },
  { path: "/password-reset", name: "PasswordReset", component: PasswordReset },
  { path: "/email-sent", name: "EmailSent", component: EmailSent },
  {
    path: "/provider/reset-password",
    name: "PasswordLogin",
    component: PasswordLogin,
  },
];

export default PublicRoutes;
