import '@scss/components/password-reset/password-reset.scss';
import InputField from '../../common/InputRuleField';
import { getValue } from '../../utils/lodash';

export default function PasswordReset(props) {
  return (
    <div className="password-reset-wrapper">
      <img
        src="/images/home-page/logo.svg"
        // className="img-fluid"
        alt=" "
        className="login-wrapper__logo-img"
      />
      <a href="/" className="password-reset-wrapper__back-link">
        <img
          src="/images/generic-images/back.svg"
          className="password-reset-wrapper__back-link-image img-fluid"
        />
        Back
      </a>
      <h2 className="password-reset-wrapper__title">Password Reset</h2>
      <div className="form-group">
        <label htmlFor="email-address">Email Address</label>
        <InputField
          inputType="TEXT"
          id="email-address"
          name='email'
          value={getValue(props,'email','')}
          onChange={props.handleChange}
          placeholder="Enter Email ID"
          className="password-reset-wrapper__form-element form-control"
          label='Email'
          validator={props.simpleValidator}
        />
      </div>
      <div className="password-reset-wrapper__cta-wrapper">
          <button className="password-reset-wrapper__cta-btn password-reset-wrapper__cta-btn--theme-primary-btn w-100" onClick={props.handleSubmit}>
            Get Reset Link
          </button>
      </div>
    </div>
  );
}
