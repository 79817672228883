import React, { useEffect, useState } from "react";
import Header from "@components/dashboard/dashboard-layout/header.js";
import Footer from "@components/dashboard/dashboard-layout/footer.js";
import "@scss/components/fresh-leads/leads-inner-page.scss";
import fetchProviderLead from "../../services/providerLead/fetchProviderLead";
import { getValue, setValue } from "../../utils/lodash";
import Moment from "react-moment";
import { toast } from "react-toastify";
import InputField from "../../common/InputRuleField";
import fetchCreateProviderLeadComment from "../../services/createProviderLeadComment/fetchCreateProviderLeadComment";
import fetchProviderLeadComment from "../../services/providerLeadComment/fetchProviderLeadComments";
import fetchUpdateProviderLead from "../../services/updateProviderLead/fetchUpdateProviderLead";
import { Col } from "reactstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import BookingFixedHeader from "@pages/components/fixed-header";
import "./booking-inner.scss";
import fetchKioskOrder from "@services/kioskOrder/fetchKioskOrders";
import moment from "moment";
import fetchUpdateKioskOrder from "@services/updateKioskOrder/fetchUpdateKioskOrder";
export default function BookingInnerPage(props) {
  const [providerLead, setProviderLead] = useState({});
  const [comment, setComment] = useState("");
  const [providerLeadComments, setProviderLeadComments] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    getProviderComments();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [statusList] = useState([
    { value: "New", label: "New" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
  ]);

  const getProviderComments = async () => {
    try {
      let resp = await fetchProviderLeadComment({
        where: { provider_lead: props.match.params.id },
      });
      if (resp) {
        console.log(resp);
        setProviderLeadComments(getValue(resp, "providerLeadComments", []));
      }
    } catch (error) {}
  };
  const [info, setInfo] = useState({});
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await fetchKioskOrder({ id: getValue(params, `id`, "") });
      if (resp) {
        setIsLoading(false);
        setInfo(getValue(resp, "kioskOrder", {}));
        setValue(
          providerLead,
          `status`,
          getValue(resp, "kioskOrder.status", "")
        );
        setProviderLead({ ...providerLead });
        console.log(resp);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Link copied to clipboard");
  };

  const handleSubmit = async () => {
    try {
      setloading(true);
      let input = {
        input: {
          data: {
            comment: comment,
            provider_user: providerLead.user.id,
            provider_lead: props.match.params.id,
          },
        },
      };
      let resp = await fetchCreateProviderLeadComment(input);
      if (resp) {
        setloading(false);
        getData();
        toast.success("Updated successfully");
      } else {
        setloading(false);
        getData();
      }
    } catch (error) {
      setloading(false);
    }
  };

  const handleUpdateStatus = async () => {
    try {
      setloading1(true);
      let input = {
        data: {
          status: getValue(providerLead, `status`, ""),
          // amount: parseFloat(getValue(providerLead, `amount`, "")),
        },
        where: {
          id: getValue(params, `id`, ""),
        },
      };
      let resp = await fetchUpdateKioskOrder(input);
      if (resp) {
        setloading1(false);
        getData();
        toast.success("Updated successfully");
        navigate(`/${getValue(params, `name`, "")}`);
      } else {
        setloading1(false);
        getData();
      }
    } catch (error) {
      setloading1(false);
    }
  };

  const handleChangeSelect = (e) => {
    setValue(providerLead, `status`, e.value);
    setProviderLead({ ...providerLead });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(providerLead, `[${name}]`, value);
    setProviderLead({ ...providerLead });
  };

  const params = useParams();
  const readableFormat = (timeString) => {
    let time = timeString
      ? moment(timeString, "HH:mm:ss.SSS").format("h:mm A")
      : "";
    return time;
  };

  return (
    <div>
      <Header />
      {isLoading ? (
        <p className="text-center mt-4">Please wait...</p>
      ) : (
        <>
          <BookingFixedHeader
            route={`/${getValue(params, `name`, "")}`}
            handleSubmit={handleUpdateStatus}
            isLoading={loading1}
          />
          <div className="inner_container">
            <div className="inner_container__left_section">
              <div className="booking_section">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text">
                      Booking # {getValue(info, `id`, "")}
                    </p>
                    <h3 className="booking_header_text mt-1 mb-3">
                      {getValue(info, `kiosk_tests.length`, 0)} Tests
                    </h3>
                  </div>
                  <div>
                    <p className="small_text">Total</p>
                    <h3 className="booking_header_text">
                      {getValue(info, `kiosk_payment.currency`, "")
                        ? getValue(info, `kiosk_payment.currency`, "")
                        : "₹"}{" "}
                      {getValue(info, `kiosk_payment.amount`, "")
                        ? getValue(info, `kiosk_payment.amount`, "")
                        : getValue(info, `amount`, "")}
                    </h3>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <p className="small_text__22">
                    {getValue(info, `kiosk.location.city.name`, "")}
                  </p>
                  <div className="dot_small mx-2"></div>
                  <p className="small_text__22">
                    {getValue(info, `kiosk.location.name`, "")}
                  </p>
                  <div className="dot_small mx-2"></div>
                  <p className="small_text__22">
                    {moment(getValue(info, `date`, "")).format(
                      "ddd, DD MMM, YYYY"
                    )}
                    {" ,"}
                    {readableFormat(getValue(info, `time`, ""))}
                  </p>
                </div>
                {/* <h6 className="mt-4">Kiosk Tests</h6> */}
                <div className="test_container mt-3">
                  {getValue(info, `kiosk_tests`, []).map((item) => {
                    return (
                      <div className="d-flex align-items-center">
                        <p className="test_container__text">
                          {getValue(item, `name`, "")}
                        </p>
                        <p className="test_container__text">:</p>
                        <p className="price_text">
                          <span className="ms-1">{"₹"}</span>{" "}
                          {getValue(item, `discount_price`, "")}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className="w-40 mt-4">
                  <p className="small_text__12">Select Status</p>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    options={statusList}
                    value={statusList.filter(
                      (item) =>
                        item.value === getValue(providerLead, `status`, "")
                    )}
                    onChange={handleChangeSelect}
                  />
                </div>
              </div>
            </div>
            <div className="inner_container__right_section">
              <div className="booking_section">
                {getValue(info, `name`, "") && (
                  <div className="right_section">
                    <p className="small_text__12 mt-1">Name</p>
                    <p className="small_text text-black">
                      {getValue(info, `name`, "")}
                    </p>
                  </div>
                )}
                {getValue(info, `phone_no`, "") && (
                  <div className="right_section">
                    <p className="small_text__12 mt-1">Phone Number</p>
                    <p className="small_text text-black">
                      {getValue(info, `phone_no`, "")}
                    </p>
                  </div>
                )}
                {getValue(info, `email`, "") && (
                  <div className="right_section">
                    <p className="small_text__12 mt-1">Email Address</p>
                    <p className="small_text text-black">
                      {getValue(info, `email`, "")}
                    </p>
                  </div>
                )}
                {getValue(info, `kiosk_payment.payment_type`, "") && (
                  <div className="right_section">
                    <p className="small_text__12 mt-1">Payment Type</p>
                    <p className="small_text text-black">
                      {getValue(info, `kiosk_payment.payment_type`, "")}
                    </p>
                  </div>
                )}
                {getValue(info, `comment`, "") && (
                  <div className="right_section">
                    <p className="small_text__12 mt-1">Comments</p>
                    <p className="small_text text-black">
                      {getValue(info, `comment`, "")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
