export default `
mutation loginKioskUser($input:LoginKioskUser!) { 
    loginKioskUser(input:$input){ 
        jwt
        user{
            id
            name
            email
        }
    }
    }
`;