import { getValue } from "@utils/lodash";
import React from "react";

function NewSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
      >
        <g
          id="Group_9084"
          data-name="Group 9084"
          transform="translate(-26 -128)"
        >
          <g id="new_releases_black_24dp" transform="translate(26 128)">
            <path
              id="Path_43901"
              data-name="Path 43901"
              d="M0,0H24V24H0Z"
              fill="none"
            />
            <path
              id="Path_43902"
              data-name="Path 43902"
              d="M23,12,20.56,9.22l.34-3.68-3.61-.82L15.4,1.54,12,3,8.6,1.54,6.71,4.72,3.1,5.53l.34,3.68L1,12l2.44,2.78L3.1,18.47l3.61.82L8.6,22.47,12,21l3.4,1.46,1.89-3.18,3.61-.82-.34-3.68Zm-4.51,2.11.26,2.79-2.74.62-1.43,2.41L12,18.82,9.42,19.93,7.99,17.52,5.25,16.9l.26-2.8L3.66,12,5.51,9.88,5.25,7.1l2.74-.61L9.42,4.08,12,5.18l2.58-1.11,1.43,2.41,2.74.62-.26,2.79L20.34,12Z"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#5f6368"
              }
            />
          </g>
          <g id="done_black_24dp" transform="translate(33 135)">
            <path
              id="Path_47519"
              data-name="Path 47519"
              d="M0,0H10V10H0Z"
              fill="none"
            />
            <path
              id="Path_47520"
              data-name="Path 47520"
              d="M5.733,10.017l-1.75-1.75L3.4,8.85l2.333,2.333,5-5L10.15,5.6Z"
              transform="translate(-1.983 -3.267)"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#5f6368"
              }
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default NewSvgComponent;
