import React, { useEffect, useState } from "react";
import DashboardLayout from "@components/layout/dashboard-layout.js";
import fetchGetTimeSlots from "@services/getTimeSlots/fetchGetTimeSlots";
import { NavLink, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import "./index.scss";
import DatePicker from "react-datepicker";
import PenSvgComponent from "@assets/svg/edit";
import moment from "moment";
import NotFound from "@components/not-found";
import fetchGetHostSlotSettings from "@services/getHostSlotSettings/fetchGetHostSlotSettings";
import SlotPopup from "./slot-popup";
import fetchHandleTimeSlotSettings from "@services/handleTimeSlotSettings/fetchHandleTimeSlotSettings";
import { removeNullValuesFromObjectArray } from "helpers/text-helpers";
import { toast } from "react-toastify";
import fetchMangeExcludeBookSlots from "@services/mangeExcludeBookSlots/fetchMangeExcludeBookSlots";
import { formatText } from "@common/text-helpers";

function ManageBookingsDetail(props) {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [date, setDate] = useState(new Date());
  const [hostSlotSettings, setHostSlotSettings] = useState([]);
  const [hostSlots, setHostSlots] = useState([]);

  useEffect(() => {
    getTimeSlots(date);
    getSettingsInfo();
  }, []);

  const getSettingsInfo = async () => {
    try {
      let input = {
        kiosk: getValue(params, `id`, ""),
      };
      let resp = await fetchGetHostSlotSettings(input);
      if (resp) {
        setHostSlotSettings(getValue(resp, `getHostSlotSettings.time`, {}));
        let list = Object.keys(getValue(resp, `getHostSlotSettings.time`, {}));
        setHostSlots(
          list.map((item) => ({
            id: getValue(resp, `getHostSlotSettings.time[${item}].id`, null),
            day: item,
            start: getValue(
              resp,
              `getHostSlotSettings.time[${item}].start`,
              null
            ),
            end: getValue(resp, `getHostSlotSettings.time[${item}].end`, null),
          }))
        );
      }
    } catch (error) {}
  };

  const getTimeSlots = async (date) => {
    let mainDate = date
      ? moment(date).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    try {
      let input = {
        date: mainDate,
        kiosk: getValue(params, `id`, ""),
      };
      setIsLoading(true);
      let resp = await fetchGetTimeSlots(input);
      if (resp) {
        setTimeSlots(getValue(resp, `getTimeSlots`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const changeBG = (value, key) => {
    switch (value) {
      case "available":
        return {
          [key]: "#05A357",
        };
      case "busy":
        return {
          [key]: "#EA4335",
        };
      case "booked":
        return {
          [key]: "#1A73E8",
        };
      case "time_expired":
        return {
          [key]: "#dadce0",
        };

      default:
        return null;
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const readableFormat = (timeString) => {
    let time = timeString
      ? moment(timeString, "HH:mm:ss.SSS").format("h:mm A")
      : "";
    return time;
  };

  const [slotLoading, setSlotLoading] = useState(false);
  const updateSlots = async () => {
    let slots = hostSlots.map((item) => ({
      ...item,
      id: getValue(params, `id`, ""),
      day: getValue(item, `start`, "") != 0 ? getValue(item, `day`, "") : null,
      start: getValue(item, `start`, "")
        ? getValue(item, `start`, "") == 0
          ? null
          : `${getValue(item, `start`, "")}` +
            `${!getValue(item, `id`, "") ? ".000.000" : ""}`
        : null,
      end: getValue(item, `end`, "")
        ? getValue(item, `end`, "") == 0
          ? null
          : `${getValue(item, `end`, "")}` +
            `${!getValue(item, `id`, "") ? ".000.000" : ""}`
        : null,
    }));
    // HH:mm:ss.SSS
    try {
      setSlotLoading(true);
      let resp = await fetchHandleTimeSlotSettings({
        input: { data: removeNullValuesFromObjectArray(slots) },
      });
      if (resp) {
        toggle();
        setSlotLoading(false);
        getTimeSlots(date);
        getSettingsInfo();
        toast.success("Updated succesfully");
      } else {
        setSlotLoading(false);
      }
    } catch (error) {
      setSlotLoading(false);
    }
  };

  const handleChangeStatus = async (time) => {
    try {
      let input = {
        date: moment(date).format("YYYY-MM-DD"),
        time: time,
        full_day: false,
        kiosk: getValue(params, `id`, ""),
      };
      let resp = await fetchMangeExcludeBookSlots(input);
      if (resp) {
        getTimeSlots(date);
        // getSettingsInfo();
        // toast.success("Updated succesfully");
      } else {
      }
    } catch (error) {}
  };

  return (
    <DashboardLayout>
      <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <NavLink to={`/manage-bookings`}>
            <img
              src="/images/generic-images/back-arrow.svg"
              className="img-fluid"
            />
          </NavLink>
          <p className="header_text ms-2">{getValue(params, `name`, "")}</p>
        </div>
        <div></div>
      </div>
      <div className="timeslot_container">
        <div className="timeslot_container_left">
          <div className="d-flex justify-content-between align-items-center px-3 pt-4">
            <h6 className="header_text color-blue">
              {moment(date).format("ddd, DD MMM, YYYY")}
            </h6>
            {/* <div>
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  // onChange={() => props.onChange(props.list)}
                  // value={props.value}
                  // checked={props.checked(props.list)}
                />
                <span className="checkmark"></span>
                <span className="ms-2">Make all slots busy</span>
              </label>
            </div> */}
          </div>
          {isLoading ? (
            <p className="text-center mt-4">Please wait...</p>
          ) : getValue(timeSlots, `length`, 0) > 0 ? (
            <div className="time_slot_list_container">
              <div
                className="d-flex flex-wrap p-2"
                style={{ border: "1px solid #dadce0", borderRadius: "5px" }}
              >
                {getValue(timeSlots, `length`, 0) > 0 &&
                  timeSlots.map((item) => {
                    return (
                      <div
                        className="item"
                        onClick={() =>
                          getValue(item, `status`, "") !== "time_expired" &&
                          handleChangeStatus(getValue(item, `value`, ""))
                        }
                      >
                        <p className="text-center item_p">
                          {getValue(item, `label`, "")}
                        </p>
                        <div className="d-flex align-items-center justify-content-center">
                          <p
                            className="dot item_p"
                            style={changeBG(
                              getValue(item, `status`, ""),
                              "background"
                            )}
                          ></p>
                          <p
                            className="text-center item_p ms-2"
                            style={changeBG(
                              getValue(item, `status`, ""),
                              "color"
                            )}
                          >
                            {formatText(getValue(item, `status`, ""))}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <NotFound />
          )}
        </div>
        <div className="timeslot_container_right">
          <div className="date_container">
            <DatePicker
              selected={date ? date : new Date()}
              onChange={(date) => {
                setDate(date);
                getTimeSlots(date);
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              peekNextMonth
              className="appointment-picker"
              // showTimeSelect
              placeholderText="Enter Date of Birth"
              inline
              dateFormat="dd MMMM yyyy"
              minDate={new Date()}
            />
          </div>
          {/* <hr /> */}
          <div className="d-flex justify-content-between align-items-center px-4 mt-4 mb-4">
            <h6 className="header_text">Settings</h6>
            <div onClick={toggle}>
              <PenSvgComponent />
            </div>
          </div>
          <div className="settings_container">
            {hostSlots.map((item) => {
              return (
                <div className="d-flex justify-content-between align-items-center px-4">
                  <p className="text">{getValue(item, `day`, "")}</p>
                  {getValue(
                    hostSlotSettings,
                    `[${getValue(item, `day`, "")}].start`,
                    ""
                  ) != 0 ? (
                    <p className="text">
                      {readableFormat(
                        getValue(
                          hostSlotSettings,
                          `[${getValue(item, `day`, "")}].start`,
                          ""
                        )
                      )}{" "}
                      -{" "}
                      {readableFormat(
                        getValue(
                          hostSlotSettings,
                          `[${getValue(item, `day`, "")}].end`,
                          ""
                        )
                      )}
                    </p>
                  ) : (
                    "-"
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <SlotPopup
        isOpen={isOpen}
        toggle={toggle}
        hostSlots={hostSlots}
        setHostSlots={setHostSlots}
        hostSlotSettings={hostSlotSettings}
        handleSubmit={updateSlots}
        isLoading={slotLoading}
      />
    </DashboardLayout>
  );
}

export default ManageBookingsDetail;
