import graphQLRequest from "../graphqlRequest";
import providerLeads from "./kioskOrders";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchKiosks(limit,start,where) {
    const datas = await graphQLRequest(providerLeads,{sort:'createdAt:desc',limit,start,where});
    return datas;
}